/* Global Styles */

@import "node_modules/quill/dist/quill.core.css";
@import "node_modules/quill/dist/quill.snow.css";

:root {	
	/* Main colours */
	--dark-lvl0: #fff; /* X */
	--dark-lvl1: #fafbfc; /* X */
	--dark-lvl2: #eee; /* X */
	--dark-lvl3: #b1b1b1; /* X */

	--light-lvl0: #3b3b3b; /* X */
	--light-lvl1: #2c2b3d; /* X */
	--light-lvl2: #575757; /* X */
	--light-lvl3: #717171; /* X */
	
	--header: #00296e;

	/* call to action elements */	
	--cta: #0a2964; /* #1473e2;*/ 
	--cta-transparent: rgba(10,41,100,0.5);
	--cta-light: #9ec0f9;
	--cta-hover: #e1953d; /*#db9a52;*/
	--row-hover: #fad7ba;
	--row-selected: #fcecdf;
	--cta-selected: #f00;
	--cta-warrning: rgb(183, 23, 23);
	--cta-accept: rgb(26, 104, 0);
	--cta-disabled: var(--light-lvl3);
	--cta-cancel: #9b9b9b;
	--cta-cancel-hover:  var(--cta-hover);

	/* buttons */
	--button-background: var(--cta);
	--button-text: var(--light-lvl0);
	--button-background-hover: var(--cta-hover);
	--button-hover-text: var(--light-lvl0);

	--approved: #090;
	--disapproved: #900;

	--dashboard-bg-lvl0: #fff;

	/* table */
	--table-text-color: var(--light-lvl1);

	/* navigation */
	--navigation-background: rgba(255,255,255,0.93);
	--navigation-hover-background: var(--dark-lvl1);
	--navigation-text: var(--light-lvl2);
	--navigation-hover-text: var(--light-lvl0);
	--navigation-width: 250px;
	--navigation-sub-width: 300px;
	--navigation-collapse-width: 50px;

	/* topView */
	--topView-height: 76px;

	/* general submenu*/ 
	--submenu-background: #444; 
	--subsubmenu-background: #555;
	--subsubsubmenu-background: var(--light-lvl3);
	--submenu-hover-background: var(--cta);

  	/* fonts */
 	--default-font-size: 0.95rem;
	--extrasmall-font-size: 0.75rem;
	--small-font-size: 0.85rem;
	--middle-font-size: 1.1rem;
	--big-font-size: 1.4rem;

	--default-font-color: #555;
	/*--default-font-family: 'Ubuntu', sans-serif;*/
	--default-font-family: 'Poppins', sans-serif;

	/* icons */
	--extralarge-fawesome-size: 48px;
	--large-fawesome-size: 28px;
	--default-fawesome-size: 20px;
	--small-fawesome-size: 14px;

	/* borders */
	--border-color: var(--dark-lvl2);

	/* statuses */
	--status_0: #d2d2d2 !important;
	--status_1: #51bd13 !important;
	--status_2: #f5a742 !important;
	--status_3: #900 !important;

	/* alarms */
	--alarm: #f00;
	--inzone: #0f0;

	--body: #f0eae9;

}

body {
	overflow: hidden !important;
}  

/* overlay */
.cdk-overlay-dark-backdrop {
    /*background: rgba(0,0,0,0.8) !important;*/
	background-image: url(/assets/images/overlay.png) !important;	
	opacity: 0.1;
}

/****************************************************************/
/* common styling */

/* Widths */
.w1 { width: 1% !important; }
.w2 { width: 2% !important; }
.w3 { width: 3.333% !important; }
.w5 { width: 5% !important; }
.w10 { width: 10% !important; }
.w15 { width: 15% !important; }
.w20 { width: 20% !important; }
.w25 { width: 25% !important; }
.w30 { width: 30% !important; }
.w33 { width: 33% !important; }
.w36 { width: 36% !important; }
.w38 { width: 38% !important; }
.w39 { width: 39% !important; }
.w40 { width: 40% !important; }
.w42 { width: 42% !important; }
.w45 { width: 45% !important; }
.w49 { width: 49% !important; }
.w50 { width: 50% !important; }
.w60 { width: 60% !important; }
.w66 { width: 66% !important; }
.w70 { width: 70% !important; }
.w80 { width: 80% !important; }
.w90 { width: 90% !important; } 
.w100 { width: 100% !important;	}

/* Widths in px */
.col.w40 { width: 40px !important; }
.col.w50 { width: 50px !important; }
.col.w75 { width: 75px !important; }
.col.w100 { width: 100px !important; }
.col.w150 { width: 150px !important; }

.wPadding {
	padding: 25px 6px 0px 6px;
}

.wMargin {
	margin: 12px 12px 12px 12px;
}

.alignRight {
	text-align: right;
}

.alignLeft {
	text-align: left;
}

.alignCenter {
	text-align: center;
}

.bold {
	font-weight: 600;
}

/*********************************************************************************************************/
/* Scrool bars  */
::-webkit-scrollbar {
	width: 4px;		
}

::-webkit-scrollbar-thumb {		
	background: linear-gradient(0deg, rgba(255,255,255,0) 0px 36px, var(--dark-lvl3) 36px calc(100% - 32px), rgba(255,255,255,0) calc(100% - 36px) 100%);	
}

::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(0deg, rgba(255,255,255,0) 0px 36px, var(--cta-hover) 36px calc(100% - 32px), rgba(255,255,255,0) calc(100% - 36px) 100%);		
}

/*******************************************/
/* modal window */
.mat-dialog-container {
	background-image: linear-gradient(to right, #f0f0f0 0%, #dae8ef 10%, #ffffff 40%, #f0efd1 80%, #ffffff 100%);
	box-shadow: 0px 0px 60px 20px rgba(0, 0, 0, 2);
	background-clip:content-box;
	background-clip: padding-box;
	background-size: 150% auto;
	animation: headerAnimation 20s ease-in-out infinite alternate;
	/*padding: 0px 12px 0px 12px !important;*/
	padding: 0px 0px 0px 0px !important;
}

.header .left,
.header .right {
	padding: 20px 30px 16px 30px;
	align-items: center;	
	display: flex;
}
 
.rightNoWrap {
	padding: 0 !important;
	align-items: center;	
	display: flex;
}

.inner_wrapper .header .left h1 {
    padding: 0 !important;
	margin: 0 !important;
    line-height: 32px;
}

.mat-dialog-container {
	position: relative;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: 24px !important; 
}

.mat-mdc-dialog-container .body {
    float: left;
    width: 100%;
    padding: 24px;
    overflow: auto;
	border-radius: 24px !important; 
    background: var(--dark-lvl0);
    border-radius: 0px 0px 24px 24px !important; 
    max-height: calc(100vh - 200px);
	position: relative;
	/*box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.3);*/
	box-shadow: 0px 6px 20px -8px rgba(0, 0, 0, 0.2);
}

/*
.mat-dialog-container .inner_wrapper::before {
	content: "";
    background-color: var(--dark-lvl0);
    left: 30px;
    right: 30px;
    
    transform: translateZ(-1px);
    position: absolute;
    box-shadow: 0px 2px 17px 0px rgb(0, 0, 0);
    bottom: 94px;
	top: 80px;
    transform: rotate(1deg);
}
*/

/*******************************************/
/* form blocks */
.modalActions {
	float: left;
    width: 100%;
    /*padding: 4px 6px 16px 6px !important;*/
	padding: 4px 18px 16px 18px !important;
}

.divider {
    margin-bottom: 2px;
	padding-top: 2px;
    border-bottom: 1px solid var(--border-color);
}

.inline {
    float: left;
}

/* Needs to be removed when all forms are redesigned */
.line {
	float: left;
	width: 100%;
}

textarea {
    resize: vertical !important;
    max-height: 400px !important;
}

input.stickToRight {
    width: calc(100% - 45px);
}

/*******************************************/
/*
*/
button:not(.circle), .button:not(.circle) {
	font-size: var(--default-font-size);
    font-weight: 400;
    padding: 12px 20px;
    border-radius: 12px;    
    color: var(--cta);
    cursor: pointer;
    margin-top: 14px;
    letter-spacing: 1px;
    font-size: var(--default-font-size);	
    background: linear-gradient(90deg, var(--cta-hover) 50%, transparent 50%);
    background-size: 200% 100%;	
    background-position: right bottom;
    transition: all 0.25s ease;
    text-decoration: none;	
	border: 2px solid var(--cta);
}

button.mat-mdc-button-base, button.mat-calendar-body-cell {
	border: none !important;
}

button.mat-mdc-button-base:hover, button.mat-calendar-body-cell:hover {
	border-radius: 100px;
	background: none;
}

.mat-calendar-controls {
	margin-top: -3px !important;
}

mat-datepicker-toggle button:hover {
	background: none;
	transition: none !important;
}

mat-datepicker-toggle button {
	background: none;
	transition: none !important;
}

.mat-datepicker-input {
    position:absolute;
    left: 0;
}

.mat-datepicker-toggle {
    position: absolute;
    top: -40px;
    right: 2px;
}

.mat-icon {
	height: 15px !important;
}

.mat-mdc-select-placeholder {
	padding-bottom: 5px;
}

.mat-mdc-select-arrow-wrapper {
	margin-bottom: 5px;
}

.mat-mdc-select {
	height: 20px;
}

.mdc-icon-button__ripple {
	top: 6px !important;
	right: 4px !important;
	left: 4px !important;
	bottom: 4px !important;
}

.mat-calendar {
	height: 370px !important;
}

.mat-mdc-form-field-hint {
	font-size: 11px;
	position: absolute;
	top: -3px;
	color: var(--default-font-color);
}

.important mat-label {
	color: var(--cta);
}

mat-form-field.mat-focused.important mat-label {
	color: var(--cta-hover);
}

.important {
	--mdc-outlined-text-field-outline-color: var(--cta);
}

.important {
	--mdc-outlined-text-field-hover-outline-color: var(--cta);
}

.important {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled)
	.mdc-notched-outline
	.mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled)
	.mdc-notched-outline
	.mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled)
	.mdc-notched-outline
	.mdc-notched-outline__trailing {
	border-width: 2px !important;
	}	
}

div#elements div.elements_line div input {
	width: 100% !important;
}

mat-form-field.mat-mdc-form-field-type-mat-select, mat-form-field.mat-focused.mat-mdc-form-field-type-mat-select {
	height: 72.5px;
}

mat-form-field.mat-mdc-form-field-type-mat-select div.mat-mdc-text-field-wrapper.mdc-text-field, 
mat-form-field.mat-mdc-form-field-type-mat-select div.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--focused,
mat-form-field.mat-mdc-form-field-type-mat-select div.mdc-notched-outline,
mat-form-field.mat-mdc-form-field-type-mat-select div {
	height: 48px;
}

mat-form-field.mat-mdc-form-field-type-mat-select div.mat-mdc-select-arrow {
	top: -10px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
	color: var(--light-lvl0) !important;
}

input, textarea {
	width: 100%;
	font-size: var(--default-font-size);
	font-weight: 300;	
	padding: 12px 12px 11px 12px;
	border: none;
	border-radius: 10px;
	border: 1px solid #aaa !important;
	color: var(--light-lvl2) !important;
	font-weight: 500;
}

button.left {
	margin-right: 12px;
}

button.right {
	margin-left: 12px;
}

button.action, .button.action {
	color: var(--dark-lvl0);  
    background: linear-gradient(90deg, var(--cta-hover) 50%, var(--cta) 50%);
    background-size: 200% 100%;	
    background-position: right bottom;
}

button.warrning, .button.warrning {
	color: var(--dark-lvl0);  
    background: linear-gradient(90deg, var(--cta-warrning) 50%, var(--cta) 50%);
    background-size: 200% 100%;	
    background-position: right bottom;
}

button.accept, .button.accept {
	color: var(--dark-lvl0);  
    background: linear-gradient(90deg, var(--cta-accept) 50%, var(--cta) 50%);
    background-size: 200% 100%;	
    background-position: right bottom;
}

button:hover, .button:hover {
	background-position: left bottom;
	background-size: 200% 100%;	
	transition: all 0.25s ease;
	color: var(--light-lvl1);	
	border-color: var(--cta-hover);
}

button.warrning:hover, .button.warrning:hover {
	background-position: left bottom;
	background-size: 200% 100%;	
	transition: all 0.25s ease;
	color: var(--dark-lvl1);	
	border-color: var(--cta-warrning);
}

button.accept:hover, .button.accept:hover {
	background-position: left bottom;
	background-size: 200% 100%;	
	transition: all 0.25s ease;
	color: var(--dark-lvl1);	
	border-color: var(--cta-accept);
}

button[disabled] {
	background: rgba(0,0,0,0.2) !important;
	color: var(--light-lvl3);
	border-color: var(--light-lvl3);
	cursor: default;
}

button:focus {	
	outline:0 !important;
}

/*******************************************/
/* tabs */

.tabs {
	float:left;
	width:100%;
	padding-left: 0px;
	border-bottom: 6px solid var(--dark-lvl2);
	margin-bottom: 12px;
}

.tabsHeader {
	width: auto;
	border-bottom: none;
	margin-bottom: 0px;
	margin-top:20px;
	margin-right:48px;
}

.tabs .tab { 
	float: left; 	  
	position: relative; 
	cursor: default;	
}

.tabs .tab:not(.active) { 
	cursor: pointer;
}

.tabs span { 
	float: left; 
	padding: 10px 22px 12px 22px; 
	text-decoration: none;
	color: var(--light-lvl0);	
	border-bottom: 6px solid var(--dark-lvl0);
	font-weight: 500; 
}

.tabs.tabsHeader span { 
	border-bottom: 6px solid rgba(255,255,255,0);
	font-size: var(--middle-font-size);
	padding: 7px 16px 16px 16px;
	color: var(--cta)
}

.tabs .active {
	z-index: 3;
}

.tabs .tab:hover {
	z-index:10;
}

.tabs .active span { 	
	/*color: var(--cta-hover);*/
	color: var(--light-lvl3);
	/*border-bottom: 6px solid var(--cta-hover);*/
	border-bottom: 6px solid var(--light-lvl3);
}

.tabs .tab:not(.active):hover span { 	
	/*border-bottom: 6px solid var(--cta-hover);*/
	color: var(--cta-hover);
}  

.tab-child {
	display: none;
	float: left;
    width: 100%;
    padding: 4px 24px 4px 24px;
}

.tab-child.active {
	display: block;
}




/*********************************/
/* material styling / modifying */
html {
	--mdc-filled-text-field-caret-color: var(--cta-hover);
	--mdc-filled-text-field-focus-active-indicator-color: var(--cta-hover);
	--mdc-filled-text-field-focus-label-text-color: var(--cta-hover);
	--mdc-filled-text-field-container-color: whitesmoke;
	--mdc-filled-text-field-disabled-container-color: #fafafa;
	--mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
	--mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
	--mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
	--mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
	--mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
	--mdc-filled-text-field-error-focus-label-text-color: #f44336;
	--mdc-filled-text-field-error-label-text-color: #f44336;
	--mdc-filled-text-field-error-caret-color: #f44336;
	--mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
	--mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
	--mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
	--mdc-filled-text-field-error-active-indicator-color: #f44336;
	--mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
	--mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
	--mdc-outlined-text-field-caret-color: var(--cta-hover);
	--mdc-outlined-text-field-focus-outline-color: var(--cta-hover);
	--mdc-outlined-text-field-focus-label-text-color: var(--cta-hover);
	--mdc-outlined-text-field-label-text-color: var(--light-lvl3);
	--mdc-outlined-text-field-disabled-label-text-color: var(--light-lvl3);
	--mdc-outlined-text-field-input-text-color: var(--light-lvl0);
	--mdc-outlined-text-field-disabled-input-text-color: var(--light-lvl3);
	--mdc-outlined-text-field-input-text-placeholder-color: var(--light-lvl0);
	--mdc-outlined-text-field-error-caret-color: #f44336;
	--mdc-outlined-text-field-error-focus-label-text-color: #f44336;
	--mdc-outlined-text-field-error-label-text-color: #f44336;
	--mdc-outlined-text-field-outline-color: var(--light-lvl2);
	--mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
    --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
	--mdc-outlined-text-field-error-hover-outline-color: #f44336;
	--mdc-outlined-text-field-error-outline-color: #f44336;
	--mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  }

  .mdc-text-field--outlined {
	--mdc-outlined-text-field-container-shape: 12px !important;
  }
  
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
	top: 23px !important;
	left: 2px !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding: 12px 0px !important;
	/*margin-left: -6px;*/
  }

  .mat-mdc-form-field {
	width: 100%;
  }

  .mat-mdc-form-field-infix {
	  min-height: 48px !important;
  }

  .mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
	padding-left: 4px;
  }

/********************************************/
/* Static wizzard */

.wizzard_container {
	position: absolute;
    right: 0px;    
    width: 100%;
	max-width: 500px;
    background: var(--light-lvl0);
    box-shadow: 0px 0px 120px 20px rgba(0, 0, 0, 0.8);
    border-radius: 24px;
    z-index: 999;
    padding: 12px 12px;    
    color: var(--dark-lvl1);
    max-height: 80vh;    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wizzard_top {
	border-bottom: 1px solid var(--light-lvl2);
	float: left;
	width: 100%;
	padding-bottom: 8px;
}

.wizzard_wrapper {	
	height: 100%;
	width: 100%;
	max-height: 50vh;
	overflow: auto;
	/*max-height: 150px;*/
}

.wizzard_container_items {
	float:left;
	width: 100%;
	/*max-height: 400px;*/	
    padding-top: 8px;
    margin-top: 8px;
}

.wizzard_selector {
	width: calc(100% - 24px);		
	overflow:auto;
	width: 100%;
	height: 100%;
}

.wizzard_item {
	float: left;
	width: calc(100% - 32px);
	padding: 6px 12px;
	margin: 2px 0px;
	border-radius: 8px;
	cursor: pointer;	
}

.wizzard_item span {
	display: inline-block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: var(--small-font-size);
	opacity: 0.6;
	font-style: italic;
	padding-top: 4px;
	padding-bottom: 4px;
}

.wizzard_item.cancel {
	float: right;	
	width: auto;
}

.wizzard_item_delete {
	float:left;
	width: 32px;
	padding-top:8px;
	padding-left: 8px;
}

.wizzard_item_delete fa-icon{
	color: var(--dark-lvl3) !important;
	cursor: pointer;
}

.wizzard_item:hover {
	background: var(--cta-hover);
}

.wizzard_item_delete fa-icon:hover {
	color: var(--cta-hover) !important;
}

/********************************************/
/* Color picker */
.colorPickerText {
	display: inline-block;
    margin-top: 18px;
    vertical-align: middle;
    margin-left: 14px;
}

.app-color-picker .preview {
	border-radius: none !important;
	box-shadow: none !important;
}

.app-color-picker .preview .circle {
	height: 48px !important;
    width: 48px !important;    
    border-radius: 12px !important;
    cursor: pointer !important;
}

.app-color-picker .preview .circle:hover {
	box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.3);
}

ngx-colors {
	float:left;
}

.input  ngx-colors .app-color-picker .preview .preview-background .circle {
	height: 46px !important;
    width: 38px !important;
    border-radius: 8px !important;
    margin-top: -5px;
}

/********************************************/
/* overrides */
.submenuItem:not(.wide) .submenuItemInner fa-icon > svg {
	height: 26px !important;
}

/*******************************************/
/* animations, keyframes */
@keyframes headerAnimation {
	0% { background-position: 0% 50%; }  
	100% { background-position: 100% 50%; }
}

/**********************************************/
/* Uploader  */
.file_container {
	transition: visibility 175ms, opacity 175ms;
	background-color: rgba(25, 128, 182, 0.9);
	position: fixed; top: 0; left: 0; bottom: 0; right: 0;
	z-index: 9999999999;
  }
  
  .file_container .uploading_progress {
	padding: 16px 0px;
  }
  
  .file_container input {	
	display: none;
	background: #fff;
    padding: 24px;
    margin: 10px 0px 20px 0px;
  }

  .file_container input.show {
	display: block;
  }
    
  .file_container .progress_filename {
	color: rgba(255,255,255,0.8);
	padding:  4px 4px;
  }
  
  .file_container .center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);    
  }
  
  .file_container .dropdown_infocontainer .info {
	color: rgba(255,255,255,0.5);
	font-weight: 200;
	font-size: 2.4rem;
  }
  
  .file_container .progress-value {
	background: rgba(25, 128, 182, 0.5);
  }

/*******************************************/
/* spinner */
.startUpSpinner {
	width: 100vw;
	height: 100vh;
	display: flex;    
  }

  .infitiny-text {
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
	font-weight: 600;
	/*color: #285dbd;*/
	color: #666;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: calc(-50vh + 90px);
	text-align: center;
	line-height: 180%;
  }

  .infitiny-loader {
	  width: 100%;
	  height: 100%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  opacity: 0.9;      
	  margin-top: -20px;
  }

  .infitiny-loader svg {
	display: block;
	max-width: 180px;
	width: 100%;
	height: 100%;
  }

  .inside {
	fill: none;
  }

  .inside {
	stroke: #285dbd;
	animation: loop 3s linear infinite;
	stroke-width: 0.85;      
	stroke-linecap: butt;
	animation-delay: -1.5s;
	stroke-dasharray: 19 9.5;
	stroke-dashoffset: 28;
  }

  .infinity {
	stroke-width: 1.2;  
  }

  @keyframes loop {
	0% { stroke-dasharray: 4 24.5; stroke-dashoffset: 28; stroke: #285dbd; }
	25% { stroke-dasharray: 19 9.5; stroke-dashoffset: 28; stroke: #e1953d; }
	75% { stroke-dasharray: 19 26.5; stroke-dashoffset: 28; stroke: #e1953d; }
	85% { stroke: #285dbd; }
	100% { stroke-dasharray: 4 24.5; stroke-dashoffset: 28; }      
  }



























































/*
.inlineButton {
    margin-right: 12px !important;
    margin-top: 0px;
	padding: 10px 10px;
	height: 46.5px;
	width: 45px;
	vertical-align: bottom;
}
*/
/* InlineButton - only in Firefox */

/*
@supports (-moz-appearance:none) {
	.inlineButton {
		height: 47.5px;
	}
}
*/
































.desktop_logo {
	display: block;
}

.mobile_logo {
	display: none;
}

#navigation.collapse .desktop_logo {
	display: none;
}

#navigation.collapse .mobile_logo {
	display: block;
}

/***********************************/

.hidden, .row.hidden {
	display: none;
}

.inner_inner {
    overflow-x: hidden !important;
}

/***********************************/
/* Spinner */
.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*********************************************************************************************************/

/* tooltip  */

div:hover > .tooltip, span:hover > .tooltip, ul > li:hover > .tooltip {
	opacity: 1;
    background: var(--light-lvl1);
	color: var(--dark-lvl0);
    padding: 4px 12px;
    white-space: nowrap;
    border-radius: 8px;
    display: block;
    transform:translateX(-50%);
	font-size: var(--small-font-size);
	box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.4);	
	transition: all 350ms;
	letter-spacing: 0.4px;
}

div:hover > .tooltip:after, span:hover > .tooltip:after, ul > li:hover > .tooltip:after {      
	width: 0;
    height: 0;    
	border-left: 8px solid transparent;
    border-right: 8px solid transparent;
	content: ' ';
    position: absolute;
    border-top: 8px solid var(--light-lvl1);
	top: 100%;
	left: calc(50% - 8px);		
	transition: all 350ms;
}

.tooltip {
	display:none;
	opacity:0;
	/*
	position: fixed;
	z-index: 1000;	
	top: 50px;
    margin-left: 10px;	
	*/
	position: absolute;
	z-index: 1000;	
	top: -42px;
    margin-left: 18px;	
	transition: all 350ms;
}

.iconTooltipWrapperInput {
	position: absolute;
	right: 0px;
	top: 13px;
}

.iconTooltipWrapperInput fa-icon:hover{
	color: var(--cta-hover);
	cursor: pointer;
}

.iconTooltipWrapperInput .tooltip {
	margin-left: 10px;
}

.topNavigationShortcut:hover > .tooltip {
	margin-top: 8px !important;
	margin-left: 12px !important;
	opacity:1;
	transition: all 350ms;
}

.topNavigationShortcut:hover > .tooltip:after {  
    content: " ";
	position: absolute;
	bottom: 100%;  /* At the top of the tooltip */
	top: auto;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent var(--cta) transparent;
	opacity:1;
}
/*********************************************************************************************************/

.blink {
    -webkit-animation: blink 2s infinite both;
            animation: blink 2s infinite both;
}

@-webkit-keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;	
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}


/*********************************************************************************************************/

/* Common */

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box !important;
  font-family: var(--default-font-family);
  font-weight: 400;
  font-size: var(--default-font-size);
  letter-spacing: 0;
}

body {
  padding: 0px;
  margin: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  /*background: var(--dark-lvl2);*/
  hyphens: auto;
  /*font-family: Roboto, "Helvetica Neue", sans-serif;*/  
}


/*
html, body { 
	height: calc(100% - 30px); 
}*/

html {	
	background-color: var(--dark-lvl1);	
		
	/*
	background-image: 
		linear-gradient(42deg, rgba(215,125,15,.04) 50%, transparent 50%),
		linear-gradient(120deg, rgba(52,83,177,.02) 50%, transparent 50%),
		linear-gradient(148deg, rgba(215,125,15,.05) 50%, transparent 50%),
		linear-gradient(18deg, rgba(52,83,177,.03) 50%, transparent 50%);
	background-size: 1200px, 800px, 1600px, 1800px, 900px;
	*/
	
    /*
    background-repeat: no-repeat;
    background-attachment: fixed;
	*/
    
}







h1 {
	font-size: 1.4rem !important;
	font-weight: 500;
	color: var(--default-font-color);
	margin: 0 0 32px;
}

h2 {
	font-size: 1.1rem;
	font-weight: 500;
	color: var(--default-font-color);
}

p {
	font-size: var(--default-font-size);
	font-weight: 300;	
	color: var(--default-font-color);
}

.small {
	font-size: 0.8rem;
	font-weight: 300;
	color: var(--default-font-color);
}

.important {
	font-weight: 700;
	color: var(--black);
}

.clickable {
	cursor: pointer;
}

.converted_href {
	color: var(--cta) !important;
}

.converted_href:hover {
	color: var(--cta-hover) !important;
}

.hr {
	float:left;
	width:100%;
	padding: 18px 0px 0px 0px;
  	margin: 18px 0px 0px 0px;
	height: 0px;
	border-top:1px solid var(--dark-lvl2);
}


strong, b {
	font-weight: 600;
}







/* Max-widths in px */

.mw150 {
	max-width: 150px !important;
}

.mw200 {
	max-width: 200px !important;
}

.mw250 {
	max-width: 250px !important;
}

.mw350 {
	max-width: 250px !important;
}

.mw400 {
	max-width: 400px !important;
}

/* Material */

.mat-dialog-container, mat-dialog-container {
	max-height: 98vh !important;
}

.cdk-overlay-pane.dark .mat-dialog-container, .cdk-overlay-pane.dark mat-dialog-container {
	max-height: 100vh !important;	
}

.cdk-overlay-pane.dark {
	max-width: 100vw !important;
}

.mat-dialog-actions {
    display: block !important;
}

.mat-dialog-actions {
    margin-bottom: 0px !important;
}

.mat-dialog-container {
	border-radius: 24px !important;
}

.inner_wrapper .header {
	overflow: auto;
	background-image: linear-gradient(to right, #f0f0f0 0%, #dae8ef 10%, #ffffff 40%, #f0efd1 80%, #ffffff 100%);
	/*box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.1);*/
	background-clip: content-box;
	background-clip: padding-box;
	background-size: 150% auto;
	animation: _ngcontent-ngx-quill-universal-c1693592886_headerAnimation 20s ease-in-out infinite alternate;
	overflow: hidden;	
}

/* Dark - on search panel */

.dark .mat-dialog-container {
	background: rgba(22, 26, 33, 0.97);
	color: var(--light-lvl3);
	margin: 20px !important;
    width: calc(100vw - 40px) !important;
    height: calc(100vh - 40px) !important;
    border-radius: 20px !important;
}

.mat-dialog-container fa-icon {
	color: var(--light-lvl1);
}

.sub_icon {
    font-size: 15px;
    position: absolute;
    bottom: -0px;
    right: -0px;
	background: var(--light-lvl0);
	color: var(--approved) !important;
}


/* Fields */

textarea {
	padding: 10px 12px 12px 12px;
	margin-bottom: -3px;
	line-height: 140%;
}

/*
select {
	min-width: 150px;
    border-radius: 10px !important;
    padding: 12px 14px;
	background: #fcf7fc !important;
	border: 1px solid #aaa !important;
	color: var(--cta) !important;	
}*/

select {

    /* styling */
    min-width: 150px;
    border-radius: 10px !important;
    padding: 12px 14px;
	border: 1px solid #aaa !important;
	color: var(--cta) !important;	
  
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

select {
	background: var(--dark-lvl0);
	background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #00338a, #003389);
    background-position: calc(100% - 23px) calc(1em + 5px), calc(100% - 15px) calc(1em + 5px), 100% 0;
    background-size: 8px 8px, 8px 8px, 45px 46.5px;
    background-repeat: no-repeat;
}
  
  select:focus {
    background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #d77d0f, #d77d0f);
    background-position: calc(100% - 23px) calc(1em + 5px), calc(100% - 15px) calc(1em + 5px), 100% 0;
    background-size: 8px 8px, 8px 8px, 45px 46.5px;
    background-repeat: no-repeat;
}

select.custom-select {
	background: none;
}

input:focus {
	border: none !important;
}

/*
input:focus, textarea:focus {
	background: #fcf7fc;
	outline: none;
	border: 1px solid var(--light-lvl2) !important;
	color: var(--cta) !important;	
}
*/

input[type=submit] {
	background: var(--button-background);
	color: var(--button-text);
}

input[type=submit]:hover {
	background: var(--button-background-hover);
	color: var(--button-hover-text);
}

.stickToLeft {    
	/*
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	*/
	margin-left: 12px !important;
}

.stickToRight {    
	/*
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	*/
	margin-left: 12px !important;
}

/* Checkbox */

.radio-wrapper, .checkbox-wrapper {
  position: relative; 
}

.radio-wrapper label, .checkbox-wrapper label {
  margin-bottom:0px;
}

input[type="checkbox"],input[type="radio"] {
  display: none;
  position:  absolute; 
  top:  0px; 
  left: 0px; 
  height: 20px; 
  width: 20px; 
  /*-webkit-appearance:  none;*/
}

.line input[type="checkbox"],
.line input[type="radio"],
.multi_line input[type="checkbox"],
.multi_line input[type="radio"] {
	float: left;
	position: relative;
}

.radio-label,
.checkbox-label {
  display: flex; 
  position: relative;
  font-size: 20px; 
  font-weight: 400;
  align-items: center; 
  justify-content: flex-start; 
}

.radio-label:before, 
.radio-label:after,
.checkbox-label:before, 
.checkbox-label:after {
  pointer-events: none; 
}

.radio-label:before,
.radio-label:after {
  border-radius:  50%; 
}

.radio-label:before,
.checkbox-label:before {
  display:  flex; 
  content: ' ';
  height:  20px; 
  width:  20px; 
  border:  solid 1px #aaa;
  border-radius:2px;
  background:  var(--light-lvl0);
}

.radio-label:after,
.checkbox-label:after {
  position:  absolute; 
  top:  7px; 
  left:  7px; 
  display:  flex; 
  content: ' '; 
  height:  12px; 
  width:  12px;  
  background: #000;
  transform: scale(0);
  transition:  transform .3s ease; 
}

.checkbox-label:after {
  height:  8px; 
  width:  14px; 
  left:  3px; 
  top: 4px;
  border-bottom:  solid 3px #000; 
  border-left:  solid 3px #000; 
  background:  none; 
  transform: rotate(-45deg) scale(0);
  transition:  transform .1s ease, opacity .1s ease;
  transform-origin: center center; 
}

.radio-wrapper input[type="radio"]:checked + .radio-label:after {
  transform:  scale(1);
}

.checkbox-wrapper input[type="checkbox"]:checked + .checkbox-label:after {
  opacity:  1; 
  transform: rotate(-45deg) scale(.8);
}


/* Bubbles */

.user_bubble {
  float:left;
  font-size: 0.9rem;
  width: 2.9rem;
  height: 2.8rem;
  line-height: 2.3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  border-radius: 50%;
  background: #f00;
  vertical-align: middle;
  margin-right: -0.6rem;
  color: white;
  border: 4px solid var(--dark-lvl1);
  font-weight: 600;
  position: relative;
}

.user_bubble_large {
  float:left;
  font-size: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  line-height: 3rem;
  letter-spacing: 0.1rem;
  text-align: center;
  border-radius: 50%;
  background: #f00;
  vertical-align: middle;
  margin-right: 0rem;
  color: white;
  border: 2px solid var(--dark-lvl1);
  font-weight: 500;
  position: relative;
}

.group_bubble {
  float:left;
  font-size: 0.8rem;
  width: 2.9rem;
  height: 2.8rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: -0.6rem;
  color: var(--light-lvl0);
  border: 2px solid var(--light-lvl0);
  font-weight: 700;
  position: relative;
  background: var(--dark-lvl0);
}

.group_bubble_small {
	float: left;
    font-size: 0.6rem;
    width: 1.5rem;
    height: 1.45rem;
    line-height: 1.3rem;
    letter-spacing: 0.025rem;
    text-align: center;
    border-radius: 50%;
    background: var(--light-lvl0);
    vertical-align: middle;
    margin-right: -0.6rem;
    color: #f0f;
    border: 2px solid #f0f;
    font-weight: 700;
    position: relative;
  }

.add_bubble {
  float:left;
  font-size: 0.8rem;
  width: 2.9rem;
  height: 2.8rem;
  line-height: 2.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  border-radius: 50%;
  background: var(--dark-lvl1);
  vertical-align: middle;
  margin-right: -0.6rem;
  color: var(--light-lvl3);
  border: 2px dashed var(--light-lvl3);
  font-weight: 700;
  position: relative;
}

.add_bubble fa-icon {
	text-align: center;
	line-height: 42px;
	color: var(--light-lvl3);
}

.bubble_add_on {
	position: absolute;
	bottom: -2px;
	right: -6px;
	background: var(--light-lvl3);
	color: #000;
	width: 16px;
	height: 16px;
	border-radius: 20px;
	padding: 1px 0px 0px 2px;
	line-height: 14px;
	font-weight: 700;
	text-align: center;
}

.user_bubble_small {
	float:left;
	font-size: 0.7rem;
	width: 2.1rem;
	height: 2.0rem;
	line-height: 1.8rem;
	letter-spacing: 0.05rem;
	text-align: center;
	border-radius: 50%;
	background: #f00;
	vertical-align: middle;
	margin-right: 0rem;
	color: white;
	border: 2px solid #fff;
	font-weight: 500;
	position: relative;  
	margin: -6px 0px 5px -8px;
}

.group_bubble_small {
	float:left;
	font-size: 0.7rem;
	width: 2.0rem;
	height: 1.9rem;
	line-height: 1.8rem;
	letter-spacing: 0.05rem;
	text-align: center;
	border-radius: 50%;
	background: var(--dark-lvl2);
	vertical-align: middle;
	margin-right: -0.6rem;
	color: #f00;
	border: 2px solid #f00;
	font-weight: 700;
	position: relative;
	margin: -6px 0px 5px -8px;
  }

  .add_bubble_small {
	float:left;
	font-size: 0.8rem;
	width: 2.1rem;
	height: 2.0rem;
	line-height: 1.8rem;
	letter-spacing: 0.05rem;
	text-align: center;
	border-radius: 50%;
	background: #fff;
	vertical-align: middle;
	margin-right: -0.6rem;
	color: var(--silver-color);
	border: 2px dashed var(--light-lvl3);
	font-weight: 700;
	margin: -6px 0px 0px -8px;
	position: relative;
  }
  
  .add_bubble_small fa-icon {
	text-align: center;
	padding-left:7px;
	line-height: 30px;
	font-size:16px;
	margin-left:-6px;
  }

/*********************************************************************************************************/

/* Statuses */

/* Status - enabled/disabled */

.status0 {
	color: var(--status_0) !important;
}

.status1 {
	color: var(--status_1) !important;
}

.status2 {
	color: var(--status_2) !important;
}

/* Customer status */

.bullet_status {
	float:left;
	width:16px;
	height:16px;
	border-radius:50px;
	background: var(--light-lvl3);
	margin:8px 10px 0px 0px;
}


/*********************************************************************************************************/

/* Flags */

.priority0 {
	color: var(--status_0) !important;
}

.priority1 {
	color: var(--status_1) !important;
}

.priority2 {
	color: var(--status_2) !important;
}

.priority3 {
	color: var(--status_3) !important;
}


/*********************************************************************************************************/

/* Alarm */

.alarm, .outofzone {
	color: var(--alarm) !important;
}

.inzone {
	color: var(--inzone) !important;
}


/*********************************************************************************************************/

/* Icons */

fa-icon {
  font-size: var(--default-fawesome-size);
  color: var(--cta);
}

.addOnIconText {
	position:absolute;
	font-size:8px;
	font-weight:600;
	letter-spacing:0.2px;
	right:4px;
	bottom:-4px;
	background:#fff;
	padding: 2px 3px;
	border-radius:3px;
	line-height: 100%;;
}

fa-icon.activity_attachment_icon, fa-icon.timetracking_view {
	color: var(--cta-disabled);
}

.active_icon {
	color: var(--cta-selected) !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.rightAlign {
	text-align: right;
}

.right fa-icon {
	cursor: pointer;
}

.right fa-icon:hover {
	color: var(--cta-hover);
}


/* Icon yes/no */

.icon_yes span {
	width:100%;
	text-align: center;
	padding-top:5px;
	color:var(--approved) !important;
}

.icon_no span {
	width:100%;
	text-align: center;
	padding-top:5px;
}

.icon_yes span {
	width:100%;
	text-align: center;
	padding-top:5px;
}

.icon_no fa-icon {
	color:var(--disapproved) !important;
}

.icon_yes fa-icon {
	color:var(--approved) !important;
}

/* Icons pop up */

.time_window fa-icon, .header_dates fa-icon {
	color: var(--light-lvl3);
}

/* Icon Microphone */

fa-icon.microphone {
	color: var(--cta);
	cursor: pointer;
}

fa-icon.microphone:hover {
	color: var(--cta-hover);
}


/*********************************************************************************************************/

/* ColorPicker */

.color_picker {
	float:left;
	width: 26px;
	height: 26px;
	margin: 0px 12px;
	background: var(--light-lvl3);
	border-radius: 3px;
}

#colorPicker {
	position: absolute;
	background: var(--light-lvl0);
	border:1px solid var(--light-lvl3);
	padding:10px 0px 0px 10px;
	max-width: 164px;
	z-index: 999;
	display: none;
}

.colorPickerChild {
	float:left;
	width: 20px;
	height: 20px;
	border-radius: 3px;
	margin:0px 10px 10px 0px;
}



/*********************************************************************************************************/
/* Select activity/customer */

.select_activity_rt .customer, .select_activity_rt .subtitle {
    color: var(--light-lvl1);
}

.customer-results .customer:hover .vat {
    color: var(--dark-lvl1);
}

.select_activity_rt .activity_type {
    color: var(--dark-lvl0);
}


/*********************************************************************************************************/

/* Tables */	

.table {
	display: table;
	width: 100%;
	color: var(--table-text-color);
}

.row {
	display: table-row;	
	width:100%;
	cursor: pointer;
}

.row.hidden {
	cursor: default;
	line-height: 1px;
}

.row:nth-child(odd) {
	background: rgba(250,250,245, 0.95);
}

.row:nth-child(even) {
	/*background: rgba(255,255,255, 0.98);*/
}

.row:hover:not(.row.header) .col:first-child {
   background: var(--row-hover);
}

.row:hover:not(.row.header) .col:last-child {
	background: var(--row-hover);
}

.row:hover:not(.row.header) .col { 
	background-color: var(--row-hover);
}

.row fa-icon {
	color: var(--table-text-color);
}

.row.selected {
	background: var(--row-selected);
}

.col {
	display: table-cell;	
	border-top:2px solid var(--dark-lvl0);
	border-left:2px solid var(--dark-lvl0);
	padding: 6px 10px;
	width: auto;	
}

.header .col {
	padding: 8px 10px;
}

.col {
	border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-left: 2px solid rgba(0, 0, 0, 0.05);
}

.col:first-child {
	border-left: 2px solid transparent;
	border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.col:last-child {
	border-right: 2px solid transparent;
	border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.col span:not(.tableStatus) {
	display: inline-block;
	max-height: 26px;
	overflow: hidden;
	padding-top: 4px;
}

/* Tables - only in Firefox */

@supports (-moz-appearance:none) {
	.row:nth-child(odd) {
		background: transparent;
	}
	
	.row:nth-child(even) {
		background: transparent;
	}

	.row:nth-child(odd) .col {
		background: rgba(255,255,255, 0.95);
	}
	
	.row:nth-child(even) .col {
		background: rgba(255,255,255, 0.98);
	}
}

.col[name=comments] span {
	width: 100% !important;
}

.col[name=comments] strong {
	color: #666;
}

/*
.row.header .col.selected.asc::after, .row.hidden .col.selected.asc::after {
    content:"";
    position: absolute;    
	width: 0; 
	height: 0; 
	margin: 8px 0px 0px 6px;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid white;
}
*/

.row.hidden .col.selected.asc::after {
	border-bottom: 5px solid var(--dark-lvl0);
}

/*
.row.header .col.selected.desc::after, .row.hidden .col.selected.desc::after {
    content:"";
    position: absolute;    
	width: 0; 
	height: 0; 
	margin: 8px 0px 0px 6px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	border-top: 5px solid white;
}
*/

.forSticky {
	display: none;
}

.row.header {
	position: sticky;	
	top: 8px;
	/*box-shadow: 0px 75px 70px 0px rgba(0,0,0,0.1);*/
	transform-style: preserve-3d;
	z-index:2;
}

.row.header:after {
    content: "";    
    background-color: var(--dark-lvl0);
	bottom: 0px;
	left: 10px;
	right: 0px;	
	top: -24px;
	transform: translateZ(-1px);    
    position: absolute;    
	z-index: -1;
	box-shadow: 0px 40px 40px 0px rgba(0,0,0,0.1);
}

.row.header.forSticky.sticky {	
	display: block;
	z-index: 9;
	position:fixed;
	margin-left: 0px;
	top: 56px;
	padding-bottom: 0px;
	background: none !important;
	border: none;
	padding: 12px 0px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/*
.row.header.sticky .col {
	border-bottom: 1px solid var(--dark-lvl0);
}
*/

.row.header.sticky div {	
	padding-bottom: 5px;
}

.row.header.sticky .col:first-child {
    border-top-left-radius: 0px;
}

.row.header.sticky .col:last-child {
    border-top-right-radius: 0px;
}

.row.hidden div {
	color: var(--dark-lvl0) !important;
	background: var(--dark-lvl0) !important;
	overflow: hidden;
	height: 1px !important;
}

.row.header .col {
	cursor: pointer;
	white-space: nowrap;
}

.row.header .col .orderFaIcon {
	cursor: pointer;
    position: absolute;
	right: 10px;
    top: 9px;
}

.row.header .col .orderIcon {
	cursor: pointer;
    position: absolute;
    margin: 0px;
    height: 0px;
    width: 0px;
    transform: rotate(45deg);
    right: 10px;
    top: 16px;
}

.row.header .col.selected.order_desc .orderIcon:after {
	content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    border-bottom: 4px solid var(--dark-lvl0);
    border-right: 4px solid var(--dark-lvl0);
    transition: all 0.2s;
    right: 0;
}

.row.header .col.selected.order_asc .orderIcon:after {
	content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    border-top: 4px solid var(--dark-lvl0);
    border-left: 4px solid var(--dark-lvl0);
    transition: all 0.2s;    
	right: -5px;
    top: 3px;
}

.row.header .col.no_selectable {
	cursor: not-allowed !important;
}

.row .col span {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	max-height: 43px;
}

.col.full_width {
	white-space: nowrap;
}

.row.header .col {
	background: var(--light-lvl1);
	color: var(--dark-lvl0);
	cursor: pointer;
}

.row.header .col:hover, .row.header .col.selected {
	background: var(--cta-hover);
}

.row.header .col.notSortable:hover {
	background: var(--cta-warrning) !important;
	cursor: not-allowed;
}

.row.header .col.selected, .row.hidden .col.selected {
	padding-right: 36px;
	font-weight: 600;
	position: relative;
}

.rows_limit, .page {
	display: inline-block;
	width: 36px;
	height: 40px;
	cursor: pointer;
	border-radius: 100px;	
	text-align: center;
	line-height: 2.8;
}

.page fa-icon {
	line-height: 1.2;
	padding-top:3px;
	vertical-align: middle;
}

.page.first, .page.last {
	padding-top: 2px;
    margin-top: -2px;
	background: none !important;
}

.page.first {
	margin-right: 8px;
}

.page.last {
	margin-left: 8px;
}

.tableFooter {
	width: 100%;
	float: left;
	position: sticky;
	bottom: 0px;
	padding-bottom: 0px;
	background: var(--light-lvl1);
	transform-style: preserve-3d;
	border-radius: 12px;
	transition: all 350ms;
}

.tableFooter:after {
    content: "";
    background-color: var(--dark-lvl0);
    top: 16px;
    left: 13px;
    right: 13px;
    height: 22px;
    transform: translateZ(-1px);
    position: absolute;
    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.6);
}

.scrollDown .tableFooter {
	bottom: -75px;
	transition: all 350ms;
}

.rows_limit.selected, .page.selected {
	color: var(--cta-hover);
	background: none;
	font-weight: 700;
}

.rows_limit:hover, .page:hover {
	color: var(--cta-hover);	
	transition: all 250mS;
}

.page:hover fa-icon {
	color: var(--cta-hover);
	transition: all 250mS;
}

.rows_limit_wrapper, .rows_view, .page_wrapper {
	padding: 0px;
}

.page_wrapper {
	float: right;
	text-align: right;
	padding-right: 6px;
	color: var(--dark-lvl1);
	margin-top: 0px;
	/*box-shadow: 0px -75px 70px 0px rgba(0, 0, 0, 0.1);*/
}

.page_wrapper fa-icon {
	color: var(--dark-lvl1);
}

.page_wrapper fa-icon:hover {
	color: var(--cta-hover);
}

.rows_limit_wrapper {
	float:left;
	width:40%;
	color: var(--dark-lvl1);
	padding-left: 6px;
}

.rows_view {
	float:left;
	width:20%;
	text-align:center;
	line-height:38px;
	color: var(--dark-lvl1);
}

/* Table header */

.tableStatus, .tableStatusMulti {
	padding: 4px 10px;
	border-radius: 8px;
	font-size: var(--extrasmall-font-size);
	font-weight: 400;
	color: var(--dark-lvl0);
	text-transform: uppercase;
	line-height: 130%;
	white-space: nowrap;
	float: left;
	letter-spacing: 1px;
	min-height: 20px;
}

.tableStatusMulti {
	background: var(--dark-lvl3);
	margin-right: 6px;
	margin-bottom: 4px;
    margin-top: 4px;
}

.tableColor {
    width: 28px;
    height: 28px;
    border-radius: 8px;
}

.table_header {
	float:left;
	width: 100%;
	padding-bottom: 12px;
    padding-top: 10px;
}

.table_header .table_header_left {
	float:left;
	margin-top: 5px;
}

.header_helper .right {
  padding-top: 8px;
	border-right: 1px solid var(--dark-lvl1);
}

.enached_table_header {
    min-height: 55.5px;
}

.enached_table_header .right.aboveSticky.open { 
    position: fixed;
    top: 74px;
    z-index: 999;
    background: var(--light-lvl1);
    padding: 12px 20px 10px 20px;
    border-radius: 12px;
    border: 5px solid var(--dark-lvl1);
    margin-left:-10px;
}

.enached_table_header .table_header_left.aboveSticky {
    position: fixed;
    z-index: 999;
    top: 35px;
}

fa-icon.table_icon svg {
    height: 14px;
    padding-right:4px;
}

.table_header .table_header_right,  .topNavigation .table_header_right {
	float: right;
	text-align: right;
	padding-right: 8px;
	color: var(--table-text-color);	
}

.table_header .table_header_left,  .topNavigation .table_header_left {
	float: left;
	text-align: left;
	padding-left: 3px;
	color: var(--table-text-color);	
}

.table_header .table_header_right, .table_header .table_header_left {
	padding-top: 3px;
}

.table_header .table_header_right input {
	margin: 0px 6px;
	padding: 10px 18px;
}

.table_header_right fa-icon {
	cursor: pointer;
	color: var(--table-text-color);	
	font-size: 24px;
	padding-right:12px;
}

.table_header_left fa-icon {
	cursor: pointer;
	color: var(--table-text-color);	
	font-size: 24px;
}

.table_header_right fa-icon:hover, .table_header_left fa-icon:hover {
	color: var(--cta-hover);
}

.table_header_right fa-icon.active, .table_header_left fa-icon.active {
	color: var(--cta);
}

.topNavigation .table_header_right fa-icon, .topNavigation .table_header_left fa-icon {
	font-size: var(--default-fawesome-size);
}

.topNavigation .table_header_right input {
	margin: 0px 6px;
	padding: 8px 16px;
}

.table_header_right.checkbox label {
	font-size: var(--default-font-size);		
}

.table_header .right fa-icon:hover {
	color: var(--cta-hover);
}

.table_header_right.search_block input {
    background: var(--dark-lvl0);
}

.nohover.header_helper {
	padding-bottom: 4px;
}

.table_title {
	float:left;
	font-size: var(--big-font-size);
	font-weight: 300;
	color: var(--table-text-color);
}

.table_title fa-icon {
	color: var(--table-text-color);
}

.table_functions {
	float:left;
	padding-left: 16px;
}

.table_header input#search, .topNavigation .right input#search {
	border-radius: 100px;
	max-width: 350px;
	border: none !important;
}

.search_block.input {
	margin-top: -6px;
	margin-right: 10px;
	margin-left: 24px;
}

.col.tableStatus {
	padding: 2px 8px;
	border-radius: 8px;
	font-size: var(--extrasmall-font-size);
	color: var(--dark-lvl0);
	text-transform: uppercase;
	line-height: 130%;
	white-space: nowrap;
	vertical-align: top;
	margin: 2px 6px;
}

.col {
	vertical-align: middle;
}

/* Tabble header tooltip */

.table_header div:hover > .tooltip::after, 
.table_header span:hover > .tooltip::after {
    border-top: 8px solid var(--cta);
}

/* DIV table */

.table {
	display: table !important;
	width:100%;
	border-collapse: separate;
	border-spacing: 0 8px;
}

.table .table_row {
	display: table-row !important;		
}

.table .row_spacer {
	float:left;
	overflow: hidden;
	height: 8px !important;
}

.table .table_col {
	display: table-column !important;
}

.table .table_cell {
	display: table-cell !important;
}

.table .cell_inner {
	width:100%;
	float:left;
	padding: 0;
	margin: 0;
	border: none;
}

.sub_activity_title.table_cell .cell_inner > .cell_inner {
	padding-left: 28px;
}

.table_cell .cell_inner > .cell_inner {
	padding-top: 8px;
}

.dialog-activity mat-dialog-container {
    padding: 0px !important;    
}

#col_selector, #category_selector {
	background: var(--light-lvl1);
	border:1px solid var(--light-lvl3);
	position: absolute;	
	display: none;
	z-index: 10;
	color: var(--dark-lvl0);
    border-radius: 10px;
}

#col_selector ul, #category_selector ul {
	list-style: none;
	padding:12px;
	margin:0;
}

#col_selector ul li, #category_selector ul li {
	padding: 4px 12px 4px 12px;
	cursor: pointer;
	position: relative;
}

#col_selector ul li:hover, #category_selector ul li:hover {
	background: var(--cta-hover);
	color: var(--light-lvl0);
}

#col_selector ul li::before, #category_selector ul li::before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 8px;
	background: var(--dark-lvl0);
}

#col_selector ul li.selected::before, #category_selector ul li.selected::before {
	background: var(--light-lvl3);
}

#col_selector ul li:hover::before, #category_selector ul li:hover::before {
	background: var(--dark-lvl0);
}

/* Table settings */

fa-icon.table_settings.open.down {
    display:none;
}

fa-icon.table_settings.up.open {
    display:none;
}

.table_settings {
    margin-left: 24px;
    opacity: 0;
    cursor: pointer;
}

.table_settings:hover {
    color: var(--cta-hover);
}


/* Tables responsive */

@media only screen and (max-width: 768px)  {
	.table_header_left .tableStatus {
		margin: 6px 0px 0px 24px;
	}
}

@media only screen and (max-width: 768px)  {
  .row.header {
    display: none !important;
  } 

  .table {
  	margin-bottom: 20px;
  }

  .col {
    display: flex;
    border: none;
  }
  
  .col::before {
    content: attr(description);
    width: 150px;
    min-width: 150px;
    text-align: left;
    font-weight: 600;
    padding-right: 15px;
  }

	.col:first-child, .col:last-child {
		border-radius: 0;
		border: none;
	}

	.table .row {
    display: block;
    margin-bottom: 12px;
    padding: 10px 0px;
    border-radius: 12px;
  }

  .row.header .col:hover, .row.header .col.selected {
  	background: none;
  }

  .table .col .master_activity, .table .row .checkbox-wrapper,  .table .row .col.w40, .row.total_row {
  	display: none;
  }

  .row:nth-child(2n) .col {
  	border-bottom: 1px solid var(--dark-lvl2);
  }

  .row:nth-child(2n+1) .col {
  	border-bottom: 1px solid var(--dark-lvl2);
  }

	.row .col.w50 {
	    width: 100% !important;
	}

	.row .col.w100 {
	    width: 100% !important;
	}

	.col span:not(.tableStatus) {
	    padding-top: 0;
	}

  .row .col:last-child {
  	border-bottom: none;
  }

  .row.hidden {
  	display: none;
  }

	.table_title {
    padding-left: 0;
  }

  .search_block.input {
    margin-right: 30px;
    margin-left: 0;
  } 

  .row.hidden {
  	display: none;
  }

  .table .row .icon_no span, .table .row .icon_yes span {
    text-align: left;
  }

  .row:hover:not(.row.header) .col:first-child {
	border-left: none;
	}

	.row:hover:not(.row.header) .col:last-child {
		border-right: none;
	}

	.row:hover:not(.row.header) .col{
        border-top: none;
        border-bottom: none;
    }
}


@media only screen and (max-width: 800px) {
  .table_header_right.search_block.input, 
  .table_header_right.search_block.icon, 
  .table_header_right .addOnIconText,
  .table_header_right.export {
  	display: none;
  }

  .table_header input#search {
  	max-width: 100%;
  }

   .col::before {
    width: auto;
    min-width: 150px;
  }

  .table_header .left {
  	margin-bottom: 0px;
  }

  .col {
    overflow-wrap: anywhere;
  }
}

/*********************************************************************************************************/

/* Tabs */



/*********************************************************************************************************/

/* Nastavitve widgetov in activity type */

.widget_settings .multi_line, .widget_settings .line {
	padding: 8px 0px;
}

.widget_wrapper {
    color: var(--light-lvl3);
	/*box-shadow: 0px 0px 5px rgba(0,0,0,0.2);*/
	border-radius: 12px;	
	position: sticky;
}

.widget_wrapper::before {
    content: '';
    position: absolute;
    inset: -4px;
    transform: skewX(1deg) skewY(1deg);
    background: var(--dark-lvl0);
    z-index: -1;
    top: 12px;
    left: 16px;
    bottom: 8px;
    right: 16px;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.75);
	transition: all 0.50s;
}

.widget_wrapper:hover {    
	/*box-shadow: 0px 0px 5px rgba(0,0,0,0.4);*/
}

.widget_wrapper:hover::before {    
    transform: skewX(-0.5deg) skewY(-0.5deg);    
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 1);
	transition: all 0.50s;
}

.widget_refresh_bar {
	float: left;
	width: 100%;
	height: 2px;
}

.widget_refresh_bar_progress {
	display: none !important;
}

.widget_header,
.dashboard_widget .current_month_header {
    border-radius: 12px 12px 0px 0px !important;
    border: none !important;
    padding: 3px 12px;
    background: var(--dark-lvl2) !important;
	transition: all 250ms;
}

.widget_header .widget_title, .dashboard_widget .current_month {
    font-weight: 600;
    color: var(--light-lvl3);
	font-size: 16px;
	letter-spacing: 0.25px;
}

.widget_wrapper:hover .widget_header .widget_title, .widget_wrapper:hover .dashboard_widget .current_month {
    color: var(--dark-lvl1);	
}

.widget_wrapper:hover .widget_header,
.dashboard_widget:hover .current_month_header,
.dashboard_widget:hover .widget_header {
    background: var(--light-lvl1) !important;
	transition: all 250ms;
	color: var(--dark-lvl0) !important;
}

.dashboard_widget:hover .widget_header .widget_title,
.dashboard_widget:hover .current_month {
	color: var(--dark-lvl0);
	transition: all 250ms;
}

.widget_wrapper:hover .widget_header fa-icon,
.dashboard_widget:hover .current_month_header fa-icon,
.dashboard_widget:hover .widget_header fa-icon {
	color: var(--dark-lvl0) !important;
	transition: all 250ms;
}

.widget_header:hover fa-icon:hover {
    color: var(--light-lvl3);
	transition: all 250ms;
}

/*
.widget_header:hover fa-icon {
    color: var(--dark-lvl1);
	transition: all 250ms;
}
*/
.widget_header:hover fa-icon:hover,
.current_month_header:hover fa-icon:hover {
    color: var(--cta-hover) !important;
}

.widget_header {
    float: left;
    width: 100%;
}

.widget_item {
    padding: 12px 20px 12px 10px;
    border: 2px solid transparent;
	border-bottom: 1px solid var(--dark-lvl2);
	cursor: pointer;
}

.widget_item:last-of-type {
 	border-bottom: none;
}

.widget_item:last-of-type, .last_item:last-of-type {
    margin-bottom: 0px !important;
}

.divider_header {
    height: 22px;
    padding: 5px 8px;
    width: 1px;
    border-left:1px solid rgba(255,255,255,0.5);
    float: right;
    border-left: 2px solid rgba(255,255,255,0.5);
    margin-left: 7px;
    margin-top: 7px;
}

.line label, .multi_line label {
	width: 25%;
}

.line-title {
	float:left;
	width:100%;
	font-size: var(--big-font-size);
	font-weight: 500;
	padding-bottom:18px;
}

.line-title fa-icon {	
	float:right;
	padding-right: 6px;
	padding-top: 2px;
}

.multi_line, .multi_line .field {
	float:left;	
}

.multi_line {
	width: 100%;
}

.multi_line label {
	float:left;	
	width:auto;
}

.multi_line .label-checkbox {
	width: auto;
	padding-right: 20px;
}


/*********************************************************************************************************/

/* Header popup */

.dialog_header {
	float:left;
	width:100%;
}

.dialog_title {
	float:left;
	width: calc(100% - 62px);
	font-weight: 600;
	font-size: var(--big-font-size);
	margin-bottom: 15px;
}

.dialog_title span {
	color: var(--dark-lvl3);
	display: block;
	padding: 4px 0 12px 0;
}

.close, .apply {
	text-align: right;
	float:right;
	width: 25px;
}


/*********************************************************************************************************/

/* The switch - the box around the slider - checkbox*/

.switch {
	position: relative;
	display: inline-block;
	width: 36px !important;
	height: 24px;
	margin-top: -2px;
	float: left;
}

.switch.disabled, .switch.disabled input, .switch.disabled span {
	cursor: default !important;
}
  
/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
  
 /* The slider */
 .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: -0px;
	bottom: 0;
	background-color: var(--dark-lvl3);
	-webkit-transition: .4s;
	transition: .4s;
 }
  
 .slider:before {
	position: absolute;
	content: "";
	height: 16px;
	width: 16px;
	left: 4px;
	bottom: 4px;
	background: var(--dark-lvl1);
	-webkit-transition: .4s;
	transition: .4s;
 }
  
 input:checked + .slider {
	background-color: var(--cta) !important;
 }
  
 /*
 input:focus + .slider {
	box-shadow: 0 0 1px var(--cta) !important;
 }
*/
  
 input:checked + .slider:before {
	-webkit-transform: translateX(12px);
	-ms-transform: translateX(12px);
	transform: translateX(12px);
 }
  
 /* Rounded sliders */

 .slider.round {
	border-radius: 34px;
 }
  
 .slider.round:before {
	border-radius: 50%;
 }


 /*********************************************************************************************************/

 /* Activity settings */

.label {
	color: var(--light-lvl3);
	padding: 4px 0 18px 10px;
}

.label-checkbox {
	padding-top: 0px !important;
	width:calc(100% - 40px);
	float:left;
	color: var(--default-font-color);
}

/*********************************************************************************************************/

/* Assign Category */

.category_option_style {
    border: 4px solid transparent;
}

.category_option_style:hover {
    border: 4px solid var(--cta-hover);
}

/* button close */

.close {
	margin-top: -7px;
}

.close fa-icon {
    font-size: 32px;
    cursor: pointer;
}

.close fa-icon:hover {
	color: var(--cta-hover);
}

.footer, .mat-dialog-actions.footer {
	padding: 24px 24px 12px 24px;
	width: calc(100% - 48px);
}

.line.actions, .footer {
    float: left;
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid var(--light-lvl3) !important;
}


.select-customer-wrapper .mat-dialog-actions.line.actions {
    padding: 16px 24px 24px 24px;
    width: calc(100% - 48px);
}

.table_header_right.search_block.icon {
	padding-right: 2px;
}

 /*********************************************************************************************************/
/* icon size */

fa-icon.ng-fa-icon svg {
	height: 20px;
}

fa-icon.ng-fa-icon.big svg {
	height: 26px;
}

fa-icon.ng-fa-icon.extraBig svg {
	height: 32px;
}

fa-icon.ng-fa-icon.small svg {
	height: 12px;
}

fa-icon.ng-fa-icon.widgetSmall svg {
	height: 18px !important;
}

.task_edit_text .add_bubble_small fa-icon svg, .activity_sub_activity_table .assign_to_activity .add_bubble fa-icon svg {
	height: 16px;
}

.topNavigation fa-icon svg, .dashboard_settings fa-icon svg, .search_filter_wrapper fa-icon svg {
	height: 27px;
}

.header_helper_left fa-icon svg {
	height: 20px;
}

fa-icon.icon_small svg {
	height: 14px;
}

.navigation li fa-icon svg {
	height: 18px;
}

fa-icon.icon_medium svg, svg.svg-inline--fa.fa-file-pdf, .close fa-icon svg, .table_header .right fa-icon svg, .table_header .table_header_right fa-icon svg, .new_widget fa-icon svg {
	height: 25px;
}

fa-icon.icon_snooze svg {
	height: 35px;
	color: #fff;
}

.header fa-icon.icon_close svg,
.innerPopup fa-icon.icon_close svg,
.inner_outer fa-icon.icon_close svg {
	height: 32px;
	cursor: pointer;
}

.header .right fa-icon.icon_medium {
	overflow: auto;
    display: inline-block;
}

.fast_activity_add .add_button fa-icon svg, .widget fa-icon svg {
	height: 40px;
}

ul.systenNavigation li > ul > li fa-icon svg {
	height: 20px;
}

fa-icon.icon_medium.close svg {
	height: 30px !important;
}

quill-editor {
	width: 100%;
}

.closeDropField svg, .burgerbar > .ng-fa-icon svg {
	height: 35px !important;
}

.file_icon fa-icon svg,
.col.name fa-icon svg {
	height: 18px !important;
}

.small.add_bubble fa-icon.ng-fa-icon svg {
    height: 12px;
}

fa-icon.remove_icon {
	color: var(--dark-lvl0) !important;
}


/*********************************************************************************************************/
/* Filters */

.center_data {
	flex: 1;
	min-width:32%;
	padding-left: 24px;
	padding-right: 24px; 
	margin-left: 24px;
	margin-left: 24px;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
}

.line.status_line .label.label-checkbox.item_label, .left_data .label.label-checkbox {
    color: var(--dark-lvl0);
}

.left_data .item_label, .center_data .label.label-checkbox {
    color: var(--light-lvl0);
    padding-top: 2px;
    padding-left: 8px;
}

.left_data .item_label {
	color: var(--dark-lvl0);
}

/* Navigation */

#navigation li {
    list-style: none !important;
}

@media (max-width: 1300px) {
	.header_helper_left.open .addon_top_navigation {
		display: block;
	}
}

/*********************************************************************************************************/
/* Calendar */

.selectedStartEnd, .selectedStart, .selectedEnd, .calendar_hours .hour.selected, .calendar_minutes .minute.selected {
    background: var(--cta);
}

.calendar_day .selected:not(.hour):not(.minute) {
    background: var(--light-lvl3);
}

.calendar_minutes .minute.selected {
    color: var(--dark-lvl1);
}

/*****************************************************************/
/* gantt */

.gantt-table-column-content div span {
    font-size: var(--extrasmall-font-size) !important;
    color: var(--dark-lvl3) !important; 
    font-weight: 400;
}

.gantt-table-column-content div {
    line-height: 16px !important;
    padding-top: 6px !important;
}

/*****************************************************************/
/* Pop up width */

.systemsettings {
    max-width: 850px!important;
    width: 100%!important;
    min-width: 650px!important;
}

/**************************************************************************************************************/

/* Common responsive design */

@media only screen and (min-width: 1024px) and (max-width: 1460px) {
	.activity_top .activity_right .activity_header .svg-inline--fa {
		vertical-align: top;
		margin-bottom: 20px !important;
	}
}

@media only screen and (max-width: 1024px) {
	.topNavigation .top_username, .topNavigation .search_block {
		display: none;
	}

	.navigation li fa-icon svg {
		height: 19px !important;
	}

	.table_title {
		padding-bottom: 0px;
		padding-top: 5px;
	}

	.table_header {
		padding-bottom: 5px;
	}

	.table_header_right fa-icon.canban, .table_header_right fa-icon.pdf {
		display: none;
	}

	.desktop_logo {
		display: none;
	}

	.mobile_logo {
		display: block;
	}

	.right.mobile {
        margin-top: 12px;
    }
}

@media only screen and (min-width: 769px) {
	.mobile_only, .mobile-only, .mobile {
		display: none;
	}
}

@media only screen and (max-width: 768px) {
	:root {
		/* topView */
		--topView-height: 62px;
	}

	.addon_top_navigation {
		display: none !important;
	}

	.desktop {
		display: none;
	}

	.navigation li fa-icon svg {
		height: 17px !important;
	}

	:not(.menu_blank) ul.navigation.navigation_top {
        height: calc(100vh - 90px) !important;
    }

	.table_header_left .tableStatus {
		margin: 6px 0px 0px 15px;
	}

	.right.mobile fa-icon {
		color: var(--light-lvl0);
		float: right;
	}

	.right.desktop.mobile {
		display: block;
		position: relative;
		background: var(--light-lvl1);
		width: 100%;
		border-radius: 10px;
		padding: 10px 0;
		margin-top: 10px;
	}

	.right.desktop.mobile .table_header_right fa-icon {
		color: var(--dark-lvl0);
	}

	.dark .mat-dialog-container {
        margin: 10px !important;
        width: 100vw !important;
    }

  	.cdk-overlay-pane, .cdk-overlay-pane.dialog-activity {
		max-width: 95vw !important;
		height: 98vh !important;
	}

	.ng-fa-icon.canban {
		display: none;
	}

	.right {
		float: right !important;
		margin-top: 3px;
		width: auto;
	}

	.cdk-drag.cdk-drag-handle.innerPopup .right {
		margin-top: 0;
	}

	.left {
		max-width: calc(100% - 64px);
	}

	.table_header.enached_table_header .left {
		max-width: 100%;
	}

	.rows_limit_wrapper {
		display: none;
	}

	.page_wrapper, .rows_view {
		float: left;
		width: 100%;
		text-align: center;
		padding-right: 0;
	}

	.rows_limit, .page {
		width: 25px;
		height: 25px;
		line-height: 2;
		font-size: var(--small-font-size);
	}

	div.tabs {
		display: none;
	}

	.tab-child {
		display: block;
	}

  	.inner_wrapper .header, .activity-search-wrapper .header, .dialog_header {
	    padding: 12px 12px 0px 12px;
	}

	.mat-h1, .mat-headline, .mat-typography h1 {
	    margin: 4px 0 12px 0;
	}

	form {
		padding: 24px 12px;
	}

	textarea[name="comment"]{
		min-height: 100px;
	}

	.inner_content {
		padding: 12px;
	}

	.col span:not(.tableStatus) {
		max-height: 39px;
	}

	.main_data, .time_data {
		overflow: visible !important;
	}

	.nohover.header_helper .table_header_right:nth-child(3) fa-icon {
		padding-right: 0px;
	}

	div#contacts.tab-child .toolbar .button {
		float: left;
		margin-bottom: 10px;
	}

	.table_header .tableStatus {
		display: none;
	}

	/* Widgets - mobile */

	.widget_content .item_date .date {
        padding-left: 0;
        font-size: var(--extrasmall-font-size);
    }

	.widget_item {
        padding: 20px 10px 10px 10px;
    }

	.widget_content.grouped .widget_item:not(.stick_up) {
        margin-top: 5px;
    } 

    .simple_activity_view .item_right {
        padding: 0px;
    }

	.widget_title {
        padding: 12px 12px 12px 0px !important;
    }

	.widget_tools {
		display: none;
	}

	.footer, .mat-dialog-actions.footer {
		padding: 24px 12px 17px 12px;
		width: calc(100% - 24px);
	}

	.innerPopup {
		padding: 16px 12px 0;
	}

	.body {
		padding: 24px 12px;
		max-height: 100% !important;
		height: calc(100vh - 188px) !important
	}

	.mat-dialog-container, mat-dialog-container {
		max-height: 97vh !important;
	}

	.inner_wrapper .header h1 {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.table_header {
		padding: 10px 10px 0 10px;
	}
}

@media screen and (max-width: 767px) {
    @-moz-document url-prefix() {
		.row .col:first-child {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
		
		.row .col:last-child {
			border-bottom-right-radius: 8px;
			border-bottom-left-radius: 8px;
		}

		.table .row {
			padding: 0px;
		}
		
    }

	.col {
		padding: 6px 12px;
	}

	.row .col:first-child {
		padding-top: 12px;
	}
	
	.row .col:last-child {
		padding-bottom: 12px;
	}

	.enached_table_header .table_header_left.aboveSticky {
		display: none;
	}


	.rows_limit_wrapper, .rows_view, .page_wrapper {
		padding: 0px 0px 10px 0px;
	}

	.inner_wrapper .page_wrapper {
		width: 100% !important;
		text-align: center !important;
	}


    .table_header_right fa-icon {
        padding-right: 0;
    }

    .table_header .table_header_right {
        padding-right: 0;
        padding-top: 9px;
    }
 }
 

/************************************/
/* Navigation : fix - AM 2023-09-17 */

.navigation li fa-icon.fastNavigationSub svg {
  height: 63px !important;
}

.navigation li {
	overflow: hidden !important;
}

ul.navigation > li fa-icon.fastNavigationSub {
	margin-right: -50px;
	transition: all 0.25s;
	z-index:5;
	display: inline-block;
}

ul.navigation > li.openNavigation fa-icon.fastNavigationSub {
	color: var(--cta) !important;
	margin-right: 0px;
	transition: all 0.25s;
}

ul.navigation > li.openNavigation .sub {
	margin-right: 0px;	
	transition: all 0.25s;
}

ul.navigation > li.openNavigation .sub {
	margin-right: -12px;
	transition: all 0.25s;
}

@media only screen and (min-width: 769px) {
	ul.navigation > li.openNavigation .sub fa-icon {
		color: var(--cta-hover) !important;
	}

	ul.navigation > li.openNavigation fa-icon.fastNavigationSub:hover {
		color: var(--cta-hover) !important;	
	}
}

.ql-toolbar {
	border-top-right-radius: 10px !important;
	border-top-left-radius: 10px !important;
}

.ql-container {
	border-bottom-right-radius: 10px !important;
	border-bottom-left-radius: 10px !important;
	margin-bottom: 20px;
}

/* Filter - remove icon */

.remove_icon {
	margin-left: 10px;
    font-weight: 500;
    border-radius: 10px;
    background: var(--dark-lvl3);
    border: none;
    cursor: pointer;
	padding: 13px 10px;
    height: 46.5px;
    width: 45px;
    vertical-align: bottom;
	text-align: center;
	display: inline-block;
}

.remove_icon:hover {
	background: var(--cta-hover);
}

/***************************/

.ql-formats button {
	margin-top: 0px !important;
}

.ql-toolbar.ql-snow {
    padding: 15px 8px !important;
}

/* Add to calendar */

.atcb-checkmark {
	display: none !important;
}

.atcb-list-item {
    padding: 8px 8px 4px 8px;
    width: calc(100% - 16px);
    float:left;
    margin: 4px 8px;
    border-radius: 6px;   
    cursor: pointer; 
}

.atcb-list-item:hover {
    background: var(--cta-hover);
}

.atcb-list {
    position: fixed;
    background: var(--dark-lvl1);
    width: 240px;
    overflow: auto;    
    padding: 4px 0px;
    border-radius: 12px;
    box-shadow: 0px 0px 36px rgb(0 0 0 / 50%);
    top: 50%;    
    left:50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}

#atcb-bgoverlay {
    position: fixed !important;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    z-index: 9999 !important;
}


.atcb-text, .atcb-icon {
    float: left;
}

.atcb-icon {
    width: 26px;
    margin-right: 12px;
}

.atcb-text {
    width: calc(100% - 60px);
    padding-top: 1px;
}

.atcb-button {    
	background: none !important;
	border: none !important;
	color: var(--light-lvl1) !important;       
    margin: -10px 12px 0px 0px;    
    padding: 12px 16px 8px 16px !important;    
    float: left;
}

.atcb-button .atcb-text {
    width: auto !important;
    letter-spacing: 0px;
    padding-top: 2px;
}    

.atcb-icon svg {
    width: 20px;    
}